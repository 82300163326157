import React, { lazy } from 'react';
import {Navigate} from "react-router-dom";


const Home = lazy(() => import("../../Component/Home"));
const Company=lazy(()=>import("../../Component/pre_requesites/company/Company"));
const Users = lazy(() => import("../../Component/Users"));
const AccessSettings=lazy(()=>import("../../Component/pre_requesites/AccessSettings"));
const Product = lazy(() => import("../../Component/products/product/Product"));
const EditProduct = lazy(() => import("../../Component/products/product/EditProduct"));
const SpecificationDetails = lazy(() => import("../../Component/products/specification_details/SpecificationDeatils"));
const Specification = lazy(() => import("../../Component/products/specification/Specification"));
const Tax = lazy(() => import("../../Component/products/tax/Tax"));
const Category = lazy(() => import("../../Component/products/category/Category"));
const Brand = lazy(() => import("../../Component/products/brand/Brand"));
const Register = lazy(() => import("../../Component/Register"));
const Login = lazy(() => import("../../Component/Login"));
const NoPage = lazy(() => import("../../Component/NoPage"));
const Subscription = lazy(() => import("../../Component/pre_requesites/Subscription"));
const BillingSettings = lazy(() => import("../../Component/pre_requesites/BillingSettings"));
const ValidityInformation = lazy(() => import("../../Component/pre_requesites/ValidityInformation"));
const Kyc = lazy(() => import("../../Component/pre_requesites/kyc/Kyc"));
const Support = lazy(() => import("../../Component/Support"));
const Faq = lazy(() => import("../../Component/Faq"));
const ChangePassword = lazy(() => import("../../Component/pre_requesites/ChangePassword"));
const Vendor = lazy(() => import("../../Component/pre_requesites/Vendor"));
const StockReport = lazy(() => import("../../Component/inventory/stock_report/StockReport"));
const ProductRate = lazy(() => import("../../Component/inventory/product_rate/ProductRate"));
const Purchase = lazy(() => import("../../Component/inventory/purchase/Purchase"));
const Pending = lazy(() => import("../../Component/process/Pending"));
const Packing = lazy(() => import("../../Component/process/Packing"));
const Trackingdetails = lazy(() => import("../../Component/process/Trackingfromshiprocket"));
const TotalOrders = lazy(() => import("../../Component/process/TotalOrders"));
const Dispatch = lazy(() => import("../../Component/process/Dispatch"));
const Billed = lazy(() => import("../../Component/process/Billed"));
const Delivered = lazy(() => import("../../Component/process/Delivered"));
const Cancel = lazy(() => import("../../Component/process/Cancel"));
const DeliveryCharges = lazy(() => import("../../Component/products/delivery_charges/DeliveryCharges"));
const BuyersCategory = lazy(() => import("../../Component/buyer/BuyersCategory"));
const BuyersList = lazy(() => import("../../Component/pre_requesites/BuyersList"));
const OfferMaster = lazy(() => import("../../Component/offers/offer/OfferMaster"));
const OfferMapping = lazy(() => import("../../Component/offers/offer/OfferMapping"));
const AddOfferRules = lazy(() => import("../../Component/offers/offer/AddOfferRules"));
const DiscountMaster = lazy(() => import("../../Component/offers/discount/DiscountMaster"));
const DiscountRules = lazy(() => import("../../Component/offers/discount/DiscountRules"));
const AddDiscountRules = lazy(() => import("../../Component/offers/discount/AddDiscountRules"));
const EditDiscountRules = lazy(() => import("../../Component/offers/discount/EditDiscountRules"));
const EditOfferRules = lazy(() => import("../../Component/offers/offer/EditOfferRules"));
const Template = lazy(() => import("../../Component/cms/templates/Template"));
const Banner = lazy(() => import("../../Component/cms/banner/Banner"));
const HomeComponent = lazy(() => import("../../Component/cms/home_component/HomeComponent"));
const AddHomeComponent = lazy(() => import("../../Component/cms/home_component/AddHomeComponent"));
const EditHomeComponent = lazy(() => import("../../Component/cms/home_component/EditHomeComponent"));
const Inventory = lazy(() => import("../../Component/inventory/stock_journal/Inventory"));
const Termsandcondition = lazy (()=> import("../../Component/terms_and_conditions/Termsandcondition"));
const Policy = lazy (()=> import("../../Component/terms_and_conditions/Policy"));
const DeliveryPolicy = lazy (()=> import("../../Component/terms_and_conditions/DeliveryPolicy"));
const RefundPolicy = lazy (()=> import("../../Component/terms_and_conditions/RefundPolicy"));
const ReturnPolicy = lazy (()=> import("../../Component/terms_and_conditions/ReturnPolicy"));
const CancellationPolicy = lazy (()=> import("../../Component/terms_and_conditions/CancellationPolicy"));
const ContactUs = lazy (()=> import("../../Component/cms/ContactUs/ContactUs"));
const About = lazy (()=> import("../../Component/cms/AboutUs/About"));
const Inventorys = lazy (()=> import("../../Component/products/Inventorys"));
const OtherMenu = lazy (()=> import("../../Component/cms/OtherMenu"));
const Country = lazy(()=>import('../../Component/pre_requesites/country/Country'));
const State = lazy(()=> import("../../Component/pre_requesites/state/ListState"));
const Area = lazy(()=> import("../../Component/pre_requesites/area/Area"));
const District = lazy(() => import("../../Component/pre_requesites/district/District"));
const TemplateCategory = lazy(()=>import("../../Component/cms/templates/template_category/TemplateCategory"))
const Payment = lazy(()=>import("../../Component/process/Payment"))
const EnquiryForm = lazy(()=>import("../../Component/terms_and_conditions/Enquiry"))
const Blogs = lazy(()=>(import("../../Component/terms_and_conditions/Blogs/Blogs")))
const ForgotPassword = lazy(()=>(import("../../Component/ForgotPassword")))

const PaymentGatewayDetails = lazy(()=>(import("../../Component/payment_and_shipment/PaymentGatewayDetail")))
const ShipRocketDetails = lazy(()=>(import("../../Component/payment_and_shipment/ShipRocketDetails")))

const CustomizeTemplate1 = lazy(()=>(import( "../../Component/cms/templates/customization/TemplateCustomize1")))
const CustomizeTemplate2 = lazy(()=>(import( "../../Component/cms/templates/customization/TemplateCustomize2")))
const CustomizeTemplate3 = lazy(()=>(import( "../../Component/cms/templates/customization/TemplateCustomize3")))
const CustomizeTemplate4 = lazy(()=>(import( "../../Component/cms/templates/customization/TemplateCustomize4")))

const ProductAndService = lazy(()=>(import("../../Component/cms/templates/Template_1/ProductAndServices")))
const WhyUs = lazy(()=>(import("../../Component/cms/templates/Template_1/WhyUs")))


const Features = lazy(()=>(import("../../Component/cms/templates/Template_2/Features")))
const WhyUsFeature = lazy(()=>(import("../../Component/cms/templates/Template_2/WhyUsFeature")))
const AvailablePlatforms = lazy(()=>(import("../../Component/cms/templates/Template_2/AvailablePlatforms")))

const WhyUsDyuken = lazy(()=>(import("../../Component/cms/templates/Template_3/WhyUsDyuken")))
const Testimonials = lazy(()=>(import("../../Component/cms/templates/Template_3/Testimonials")))
const AboutUsImages = lazy(()=>(import("../../Component/cms/templates/Template_3/AboutUsImages")))
const Cancelrequestfromcustomer  = lazy(()=>(import("../../Component/process/Cancelrequestfromcustomer")))
const Canceltype  = lazy(()=>(import("../../Component/payment_and_shipment/Canceltype")))
const PendingSettlement = lazy(()=>(import("../../Component/Report/PendingSettlement")))
const PaidSettlement = lazy(()=>(import("../../Component/Report/PaidReport")))

const FollowUsOnInstagram = lazy(()=>(import("../../Component/cms/templates/Template_4/FollowUsOnInstagram")))
const OtherSupports = lazy(()=>(import("../../Component/cms/templates/Template_4/OtherSupports/OtherSupports")))
const Subscriberlist = lazy(()=>(import("../../Component/terms_and_conditions/Subscriberlist")))
const DeliveryCharge = lazy(()=>(import("../../Component/products/deliverycharge/DeliveryCharge")))
const System=lazy(()=>import("../../Component/System"))
// const SystemSettings=lazy(()=>import("../../Component/pre_requesites/sysytem_settings/SystemSettings"))
const SMSConfiguration=lazy(()=>import("../../Component/SMSConfiguration"))
const CouponMenu = lazy(()=>import("../../Component/CouponMenu/CouponMenu"))

const authrouter = [
  {
    index: true,
    exact: true,
    path: "/",
    element: <Home />,
    navigate: <Navigate to="/login" replace />
  },
  {
    index:true,
    exact: true,
    path: "/terms-and-condition",
    element: <Termsandcondition />,
    navigate: <Navigate to="/login" replace />

  },
  {
    index:true,
    exact: true,
    path: "/delivery-charge",
    element: <DeliveryCharge />,
    navigate: <Navigate to="/login" replace />
  },
  {
    index:true,
    exact: true,
    path: "/other-menus-pages",
    element: <OtherMenu />,
    navigate: <Navigate to="/login" replace />

  },
  {
    index:true,
    exact: true,
    path: "/subscriberlist",
    element: <Subscriberlist />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/payment-gateway",
    element: <PaymentGatewayDetails />,
    navigate: <Navigate to="/" replace />
  },
  {
    exact: true,
    path: "/ship-rocket-details",
    element: <ShipRocketDetails />,
    navigate: <Navigate to="/" replace />
  },
  {
    exact: true,
    path: "/System",
    element: <System />,
    navigate: <Navigate to="/" replace />
  },
  // {
  //   exact: true,
  //   path: "/system_settings",
  //   element: <SystemSettings />,
  //   navigate: <Navigate to="/" replace />
  // },
  {
    exact: true,
    path: "/SMSConfiguration",
    element: <SMSConfiguration />,
    navigate: <Navigate to="/" replace />
  },
  {
    exact: true,
    path: "/settlement",
    element: <PendingSettlement />,
    navigate: <Navigate to="/" replace />
  },
  {
    exact: true,
    path: "/paidsettlement",
    element: <PaidSettlement />,
    navigate: <Navigate to="/" replace />
  },
  {
    exact: true,
    path: "/canceltype",
    element: <Canceltype/>,
    navigate: <Navigate to="/" replace />
  },
  {
    index:true,
    exact: true,
    path: "/about",
    element: <About />,
    navigate: <Navigate to="/login" replace />

  },
  {
    index:true,
    exact: true,
    path: "/inventorys",
    element: <Inventorys />,
    navigate: <Navigate to="/login" replace />

  },
  {
    index:true,
    exact: true,
    path: "/contact-info",
    element: <ContactUs/>,
    navigate: <Navigate to="/login" replace />

  },
  {
    index:true,
    exact: true,
    path: "/privacy-policy",
    element: <Policy/>,
    navigate: <Navigate to="/login" replace />
  },
  {
    index:true,
    exact: true,
    path: "/deliverypolicy",
    element: <DeliveryPolicy/>,
    navigate: <Navigate to="/login" replace />
  },
  {
    index:true,
    exact: true,
    path: "/blogs",
    element: <Blogs/>,
    navigate: <Navigate to="/login" replace />
  },
  {
    index:true,
    exact: true,
    path: "/refundpolicy",
    element: <RefundPolicy/>,
    navigate: <Navigate to="/login" replace />
  },{
    index:true,
    exact: true,
    path: "/tracking_details_from_shiprocket",
    element: <Trackingdetails/>,
    navigate: <Navigate to="/login" replace />
  },
  {
    index:true,
    exact: true,
    path: "/returnpolicy",
    element: <ReturnPolicy/>,
    navigate: <Navigate to="/login" replace />
  },
  {
    index:true,
    exact: true,
    path: "/cancellationpolicy",
    element: <CancellationPolicy/>,
    navigate: <Navigate to="/login" replace />
  },
  {
    index:true,
    exact: true,
    path: "/customize_template/Theme-1",
    element: <CustomizeTemplate1 />,
    navigate: <Navigate to="/login" replace />
  },
  {
    index:true,
    exact: true,
    path: "/customize_template/Theme-2",
    element: <CustomizeTemplate2 />,
    navigate: <Navigate to="/login" replace />
  },
  {
    index:true,
    exact: true,
    path: "/customize_template/Theme-3",
    element: <CustomizeTemplate3 />,
    navigate: <Navigate to="/login" replace />
  },
  {
    index:true,
    exact: true,
    path: "/customize_template/Theme-4",
    element: <CustomizeTemplate4 />,
    navigate: <Navigate to="/login" replace />
  },
  {
    index:true,
    exact: true,
    path: "/product_and_service",
    element: <ProductAndService />,
    navigate: <Navigate to="/login" replace />
  },
  {
    index:true,
    exact: true,
    path: "/why_us",
    element: <WhyUs />,
    navigate: <Navigate to="/login" replace />
  },
  {
    index:true,
    exact: true,
    path: "/features",
    element: <Features />,
    navigate: <Navigate to="/login" replace />
  },
  {
    index:true,
    exact: true,
    path: "/why_us_feature",
    element: <WhyUsFeature />,
    navigate: <Navigate to="/login" replace />
  },
  {
    index:true,
    exact: true,
    path: "/available_platforms",
    element: <AvailablePlatforms />,
    navigate: <Navigate to="/login" replace />
  },
  {
    index:true,
    exact: true,
    path: "/whyus_dyuken",
    element: <WhyUsDyuken />,
    navigate: <Navigate to="/login" replace />
  },
  {
    index:true,
    exact: true,
    path: "/follow_us_on_instagram",
    element: <FollowUsOnInstagram />,
    navigate: <Navigate to="/login" replace />
  },
  {
    index:true,
    exact: true,
    path: "/other_supports",
    element: <OtherSupports />,
    navigate: <Navigate to="/login" replace />
  },
  {
    index:true,
    exact: true,
    path: "/testimonials",
    element: <Testimonials />,
    navigate: <Navigate to="/login" replace />
  },
  {
    index:true,
    exact: true,
    path: "/aboutus_images",
    element: <AboutUsImages />,
    navigate: <Navigate to="/login" replace />
  },
  // {
  //   index:true,
  //   exact: true,
  //   path: "/customize_template/Theme-1",
  //   element: <CustomizeTemplate1 />,
  //   navigate: <Navigate to="/login" replace />
  // },
  {
    exact: true,
    path: "/home-component",
    element: <HomeComponent />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/edit-homecomponent/:id",
    element: <EditHomeComponent />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/templates",
    element: <Template />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/banner",
    element: <Banner />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/company",
    element: <Company />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/user",
    element: <Users />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/access-settings",
    element: <AccessSettings />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/editproduct/:id",
    element: <EditProduct />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/product",
    element: <Product />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/specification",
    element: <Specification />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/specification-details",
    element: <SpecificationDetails />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/tax",
    element: <Tax />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/category",
    element: <Category />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/brand",
    element: <Brand />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/subscription",
    element: <Subscription />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/billing-settings",
    element: <BillingSettings />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/validity-information",
    element: <ValidityInformation />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/kyc",
    element: <Kyc />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/support",
    element: <Support />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/faq",
    element: <Faq />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/change-password",
    element: <ChangePassword />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/vendor",
    element: <Vendor />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/stock-report",
    element: <StockReport />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/product-rate",
    element: <ProductRate />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/stock-journal",
    element: <Inventory />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/orders",
    element: <Pending />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/purchase",
    element: <Purchase />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/purchase",
    element: <Purchase />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/total_orders",
    element: <TotalOrders />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/shipment",
    element: <Billed />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/packing",
    element: <Packing />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/dispatch",
    element: <Dispatch />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/delivered",
    element: <Delivered />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/cancel",
    element: <Cancel />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/cancelrequestfromcustomer",
    element: <Cancelrequestfromcustomer />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/payment",
    element: <Payment />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/enquiryform",
    element: <EnquiryForm />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/delivery-charge",
    element: <DeliveryCharges />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/buyers-category",
    element: <BuyersCategory />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/buyerslist",
    element: <BuyersList />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/offer-master",
    element: <OfferMaster />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/offer-rules",
    element: <OfferMapping />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/addoffer-rules",
    element: <AddOfferRules />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/editoffer-rules/:id",
    element: <EditOfferRules />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/discount-master",
    element: <DiscountMaster />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/discount-rules",
    element: <DiscountRules />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/couponmenu",
    element: <CouponMenu />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/adddiscount-rules",
    element: <AddDiscountRules />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/editdiscount-rules/:id",
    element: <EditDiscountRules />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/state",
    element: <State />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/area",
    element: <Area />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/district",
    element: <District />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/country",
    element: <Country />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/templatecategory",
    element: <TemplateCategory />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: '',
    path: "*",
    element: <NoPage />,
    navigate: ''
  },
  
];
const commonrouter = [
  {
    exact: true,
    path: "/signup",
    element: <Register />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/",
    element: <Navigate to="/login" replace />,
    navigate: <Login />
  },
  {
    exact: true,
    path: "/login",
    element: <Login />,
    navigate: <Navigate to="/" replace />
  },
  {
    exact: true,
    path: "/forgot_password",
    element: <ForgotPassword />,
    navigate: <Navigate to="/" replace />
  },
  {
    exact: '',
    path: "*",
    element: <NoPage />,
    navigate: ''
  },
];
export { authrouter, commonrouter };