import axios from "axios";
const BASE_URL = process.env.REACT_APP_API;
const BASE_URL_WEB = process.env.REACT_APP_API_WEBSITE;
const user = JSON.parse(localStorage.getItem("persist:root"))?.admin;
const isadmin = user && JSON.parse(user)?.admin?.token;
// console.log("BASE_URL_WEB", BASE_URL)
// console.log("REACT_APP_API_WEBSITE", process.env.REACT_APP_API_WEBSITE)
//token request
export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    authorization: `Bearer ${isadmin}`,
  },
});
//without token request
export const getRequest = axios.create({
  baseURL: BASE_URL,
});
//public request
export const publicRequest = axios.create({
  baseURL: BASE_URL,
});
//website request
export const websiteRequest = axios.create({
  baseURL: BASE_URL_WEB,
});






