import { getRequest,userRequest,publicRequest,websiteRequest } from "../RequestMethod/RequestMethod";
import {
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGOUT
} from '../Contans/AdminContans';

class API {
  constructor() {
    this.rootUrl = process.env.REACT_APP_API;
    this.rootUrl1 = process.env.REACT_APP_IMAGE;
  }

  login = async (dispatch, data) => {
    try {
      dispatch({ type: ADMIN_LOGIN_REQUEST });
      const res = await getRequest.post("login", data);
      if (res.data.success === true) {
        dispatch({ type: ADMIN_LOGIN_SUCCESS, payload: res.data });
      } else {
        dispatch({ type: ADMIN_LOGIN_FAIL, payload: res.data });
      }
      return res;
    } catch (error) {
      const res = error.response;
      dispatch({
        type: ADMIN_LOGIN_FAIL,
        payload: null,
      });
      return res;
    }
  }

  //logout

  logout = async (dispatch) => {
    dispatch({ type: ADMIN_LOGOUT, payload: null });
  }

  // crud service
  getAll = async (url,params) => {
    try {
      const res = await userRequest.get(url,{params:params});
      return res;
    } catch (error) {
      return error;
    }
  };

  getAll1 = async (url,params) => {
    try {
      const res = await websiteRequest.get(url,{params:params});
      return res;
    } catch (error) {
      return error;
    }
  };

  getSingle = async (url,params) => {
    try {
      const res = await userRequest.get(`${url}/${params}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  getSingle_q = async (url,params) => {
    try {
      const res = await userRequest.get(`${url}?${params}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  create = async (url,data) => {
    try {
      const res = await userRequest.post(url,data);
      return res;
    } catch (error) {
      return error;
    }
  };

  create2 = async (url,data) => {
    try {
      const res = await websiteRequest.post(url,data);
      return res;
    } catch (error) {
      return error;
    }
  };

  create1 = async (url,params,data) => {
    try {
      const res = await userRequest.post(url,params,data);
      return res;
    } catch (error) {
      return error;
    }
  };
  
  update = async (url,params,data) => {
    try {
      const res = await userRequest.put(`${url}/${params}`,data);
      return res;
    } catch (error) {
      return error;
    }
  };
  updateStatus = async (url,data) => {
    try {
      const res = await userRequest.put(`${url}`,data);
      return res;
    } catch (error) {
      return error;
    }
  };

  
  remove = async (url,params) => {
    try {
      const res = await userRequest.delete(`${url}/${params}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  removeimage = async (url,params,body) => {
    try {
      const res = await userRequest.put(`${url}/${params}`,body);
      return res;
    } catch (error) {
      return error;
    }
  };

  // common crud service
  createCommon = async (url,data) => {
    try {
      const res = await userRequest.post(url,data);
      return res;
    } catch (error) {
      return error;
    }
  };

  getCommonAll = async (url,params) => {
    try {
      const res = await publicRequest.get(url,{params:params});
      return res;
    } catch (error) {
      return error;
    }
  };

  stepOne = async(url, params)=>{
    try {
      const res = await publicRequest.post(url,{params:params});
      return res;
    } catch (error) {
      return error;
    }
  }
  stepTwo = async(url, params)=>{
    try {
      const res = await publicRequest.put(url,{params:params});
      return res;
    } catch (error) {
      return error;
    }
  }

  
  
};



export default API;
