import React, { useState, useEffect } from 'react'
import styled from "styled-components";
import { FormOutlined, PlusOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import {
    Button,
    Modal,
    Form,
    Input,
    Col,
    Row,
    message,
    Upload,
    Image,
    Select,
  } from "antd";
  import crud_service from "../../../Store/Api/CrudService";
  import API from '../../../Store/Api/ApiService';
  import { useNavigate } from "react-router-dom";

const SystemSettings = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();
    const [form4] = Form.useForm();
    const [form5] = Form.useForm();
    const [form6] = Form.useForm();
    const [selectedGateway, setSelectedGateway] = useState(null);

    const crud = new crud_service();

    let company = {
        company_code: localStorage.getItem("company")
    }

    // console.log('company', company?.company_code);
    const initRender = async () => {
        //paymentgateway
        await crud.getSingle_q("paymentform", `company_code=${company?.company_code}`, async (err, res) => {
          if (res?.data?.success === true) {
            // console.log('paymentform', res?.data?.data[0]);
            form4.setFieldsValue(res?.data?.data[0]); 
            form4.setFieldValue("status", res?.data?.data[0]?.status === true ? 1 : 0);           
          }
          else if (res?.response?.status === 500) {
            message.error(res?.response?.data?.message);
          } else {
            message.error(res?.message);
          }
        });

        //shiprocket
        await crud.getSingle_q("shiprocketform", `company_code=${company?.company_code}`, async (err, res) => {
          if (res?.data?.success === true) {
            // console.log('shiprocket', res?.data?.data[0]);
            form5.setFieldsValue(res?.data?.data[0]); 
            form5.setFieldValue("status", res?.data?.data[0]?.status === true ? 1 : 0);           
          }
          else if (res?.response?.status === 500) {
            message.error(res?.response?.data?.message);
          } else {
            message.error(res?.message);
          }
        });

        //mail
        await crud.getSingle_q("mailform", `company_code=${company?.company_code}`, async (err, res) => {
          if (res?.data?.success === true) {
            // console.log('mail', res?.data?.data[0]);
            form.setFieldsValue(res?.data?.data[0]); 
            form.setFieldValue("status", res?.data?.data[0]?.status === true ? 1 : 0);           
          }
          else if (res?.response?.status === 500) {
            message.error(res?.response?.data?.message);
          } else {
            message.error(res?.message);
          }
        });

        //sms
        await crud.getSingle_q("smsform", `company_code=${company?.company_code}`, async (err, res) => {
          if (res?.data?.success === true) {
            // console.log('sms', res?.data?.data[0]);
            form1.setFieldsValue(res?.data?.data[0]); 
            form1.setFieldValue("status", res?.data?.data[0]?.status === true ? 1 : 0);           
          }
          else if (res?.response?.status === 500) {
            message.error(res?.response?.data?.message);
          } else {
            message.error(res?.message);
          }
        });

        //e-invoice
        await crud.getSingle_q("einvoice", `company_code=${company?.company_code}`, async (err, res) => {
          if (res?.data?.success === true) {
            // console.log('einvoic', res?.data?.data[0]);
            form2.setFieldsValue(res?.data?.data[0]); 
            form2.setFieldValue("status", res?.data?.data[0]?.status === true ? 1 : 0);           
          }
          else if (res?.response?.status === 500) {
            message.error(res?.response?.data?.message);
          } else {
            message.error(res?.message);
          }
        });

        //upi
        await crud.getSingle_q("upiform", `company_code=${company?.company_code}`, async (err, res) => {
          if (res?.data?.success === true) {
            // console.log('upiform', res?.data?.data[0]);
            form3.setFieldsValue(res?.data?.data[0]); 
          }
          else if (res?.response?.status === 500) {
            message.error(res?.response?.data?.message);
          } else {
            message.error(res?.message);
          }
        });

        //map
        await crud.getSingle_q("mapform", `company_code=${company?.company_code}`, async (err, res) => {
          if (res?.data?.success === true) {
            // console.log('mapform', res?.data?.data[0]);
            form6.setFieldsValue(res?.data?.data[0]); 
          }
          else if (res?.response?.status === 500) {
            message.error(res?.response?.data?.message);
          } else {
            message.error(res?.message);
          }
        });

      };

      //init
      useEffect(() => {
        initRender();
      }, []);

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };
    const handleGatewayChange = (value) => {
        console.log(`selected handleGatewayChange ${value}`);
        setSelectedGateway(value);
    };

    useEffect(() => {
        const selectedGatewayValue = form4.getFieldValue("pg_gateway");
        setSelectedGateway(selectedGatewayValue);
      }, [form4]);

    const onMailFinish = async (values) => {
        console.log("SucconMailFinishess:", values);

        await crud.create('mailform', values, (err, res) => {
            if (res?.data?.success === true) {
              message.success(res?.data?.message);
              initRender();
            } else if (res?.response?.status === 500) {
              message.error(res?.response?.data?.message);
            } else {
              message.error(res?.message);
            }
          });
    };
    const onSMSFinish = async (values) => {
        console.log("onSMSFinish:", values);

        await crud.create('smsform', values, (err, res) => {
            if (res?.data?.success === true) {
              message.success(res?.data?.message);
              initRender();
            } else if (res?.response?.status === 500) {
              message.error(res?.response?.data?.message);
            } else {
              message.error(res?.message);
            }
          });
    };
    const onEinvoiceFinish = async (values) => {
        console.log("onEinvoiceFinish:", values);

        await crud.create('einvoice', values, (err, res) => {
            if (res?.data?.success === true) {  
              message.success(res?.data?.message);
              initRender();
            } else if (res?.response?.status === 500) {
              message.error(res?.response?.data?.message);
            } else {
              message.error(res?.message);
            }
          });
    };
    const onUPIFinish = async (values) => {
        console.log("onUPIFinish:", values);

        await crud.create('upiform', values, (err, res) => {
            if (res?.data?.success === true) {
              message.success(res?.data?.message);
                initRender();        
            } else if (res?.response?.status === 500) {
              message.error(res?.response?.data?.message);
            } else {
              message.error(res?.message);
            }
          });
    };
    const onPaymentGatewayFinish = async (values) => {
        console.log("onPaymentGatewayFinish:", values);

        await crud.create('paymentform', values, (err, res) => {
            if (res?.data?.success === true) {
              message.success(res?.data?.message);
              initRender();
            } else if (res?.response?.status === 500) {
              message.error(res?.response?.data?.message);
            } else {
              message.error(res?.message);
            }
          });
    };

    const onShiprocketFinish = async (values) => {
        console.log("onShiprocketFinish:", values);

        await crud.create('shiprocketform', values, (err, res) => {
            if (res?.data?.success === true) {
              message.success(res?.data?.message);
              initRender();
            } else if (res?.response?.status === 500) {
              message.error(res?.response?.data?.message);
            } else {
              message.error(res?.message);
            }
          });
    };
    const onMapFinish = async (values) => {
        console.log("onMapFinish:", values);

        await crud.create('mapform', values, (err, res) => {
            if (res?.data?.success === true) {
              message.success(res?.data?.message);
              initRender();   
            } else if (res?.response?.status === 500) {
              message.error(res?.response?.data?.message);
            } else {
              message.error(res?.message);
            }
          });
    };

  return (
    <React.Fragment>  
        <SysytemSettings>
            <div className="page_back_align" style={{ display: "inline-block"}}>
                <p onClick={() => navigate(-1)} className="go_back" style={{ width: "100%" }}>
                    <ArrowLeftOutlined /> &nbsp; System Settings
                </p>
            
                <div>
                    <div className="bg_box" style={{marginTop: "20px"}}>
                        <SystemSettingsWrapper>
                            <div className="primary_section">
                                <h3 style={{fontWeight: "bold", marginTop: "0px"}}>Mail</h3>
                                <Form
                                    name="mail"
                                    layout="inline"                                   
                                    // onFinish={onFinish}
                                    form={form}   
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        justifyContent: 'space-between',
                                        gap: '20px'  // Adjust the gap value as needed
                                    }}                                 
                                >
                                    <Form.Item
                                        label="From"
                                        name="from"
                                        style={{ flex: '1 1 30%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "From is required!",
                                            },
                                            {
                                                type: "email",
                                                message: "Please enter a valid email!",
                                            }
                                        ]}
                                    >
                                        <Input style={{ width: "100%"}}/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Cc"
                                        name="cc"
                                        style={{ flex: '1 1 30%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Cc is required!",
                                            },
                                            {
                                                type: "email",
                                                message: "Please enter a valid email!",
                                            }
                                        ]}
                                    >
                                        <Input style={{ width: "100%"}}/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Status"
                                        name="status"
                                        style={{ flex: '1 1 30%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Status is required!",
                                            },
                                        ]}
                                    >
                                         <Select
                                            // defaultValue=''
                                            placeholder="Select Mail Status"
                                            style={{
                                                width: "100%",
                                            }}
                                            onChange={handleChange}
                                            options={[
                                                // {
                                                // value: '',
                                                // label: 'Select',
                                                // },
                                                {
                                                value: 1,
                                                label: 'Enable',
                                                },
                                                {
                                                value: 0,
                                                label: 'Disable',
                                                },
                                            ]}
                                            />
                                    </Form.Item>
                                    <Form.Item
                                            label="company code"
                                            name="company_code"
                                            style={{ flex: '1 1 30%' }}  
                                            initialValue={`${company?.company_code}`}   
                                            hidden                                      
                                    >
                                            <Input style={{ width: "100%"}} />
                                    </Form.Item>
                                </Form>
                            </div>
                            <Form form={form} onFinish={onMailFinish} style={{marginTop: "20px"}}>
                                <Form.Item style={{marginTop: "20px"}}>
                                    <Button type="primary" htmlType="submit">
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </SystemSettingsWrapper>
                    </div>

                    <div className="bg_box" style={{marginTop: "20px"}}>
                        <SystemSettingsWrapper>
                            <div className="primary_section">
                                <h3 style={{fontWeight: "bold", marginTop: "0px"}}>SMS</h3>
                                <Form
                                    name="sms"
                                    layout="inline"
                                    // onFinish={onFinish1}
                                    form={form1}
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        justifyContent: 'space-between',
                                        gap: '20px'  // Adjust the gap value as needed
                                    }}
                                >
                                    <Form.Item
                                        label="User Name"
                                        name="user_name"
                                        style={{ flex: '1 1 30%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "User Name is required!",
                                            },
                                        ]}
                                    >
                                        <Input style={{ width: "100%"}}/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Password"
                                        name="password"
                                        style={{ flex: '1 1 30%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Password is required!",
                                            },
                                            {
                                                pattern: /[0-9a-zA-Z]{6,}/,
                                                message: "Password should be atleast 6 characters!",
                                            }
                                        ]}
                                        hasFeedback
                                    >
                                        <Input.Password style={{ width: "100%"}}/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Sender Id"
                                        name="sender_id"
                                        style={{ flex: '1 1 30%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Sender Id is required!",
                                            },
                                        ]}
                                    >
                                        <Input style={{ width: "100%"}}/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Status"
                                        name="status"
                                        style={{ flex: '1 1 30%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Status is required!",
                                            },
                                        ]}
                                    >
                                         <Select
                                            // defaultValue=''
                                            placeholder="Select SMS Status"
                                            style={{
                                                width: "100%",
                                            }}
                                            onChange={handleChange}
                                            options={[
                                                // {
                                                // value: '',
                                                // label: 'Select',
                                                // },
                                                {
                                                value: 1,
                                                label: 'Enable',
                                                },
                                                {
                                                value: 0,
                                                label: 'Disable',
                                                },
                                            ]}
                                            />
                                    </Form.Item>
                                    <Form.Item
                                            label="company code"
                                            name="company_code"
                                            style={{ flex: '1 1 30%' }}  
                                            initialValue={`${company?.company_code}`}   
                                            hidden                                      
                                    >
                                            <Input style={{ width: "100%"}} />
                                    </Form.Item>
                                </Form>
                            </div>
                            <Form form={form1} onFinish={onSMSFinish} style={{marginTop: "20px"}}>
                                <Form.Item style={{marginTop: "20px"}}>
                                    <Button type="primary" htmlType="submit">
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </SystemSettingsWrapper>
                    </div>

                    <div className="bg_box" style={{marginTop: "20px"}}>
                        <SystemSettingsWrapper>
                            <div className="primary_section">
                                <h3 style={{fontWeight: "bold", marginTop: "0px"}}>E-invoice</h3>
                                <Form
                                    name="einvoice"
                                    layout="inline"
                                    // onFinish={onFinish2}
                                    form={form2}
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        justifyContent: 'space-between',
                                        gap: '20px'  // Adjust the gap value as needed
                                    }}
                                >
                                    <Form.Item
                                        label="IP Address"
                                        name="ip_address"
                                        style={{ flex: '1 1 30%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "IP Address is required!",
                                            },
                                        ]}
                                    >
                                        <Input style={{ width: "100%"}}/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Client Id"
                                        name="client_id"
                                        style={{ flex: '1 1 30%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Client Id is required!",
                                            },
                                        ]}
                                    >
                                        <Input style={{ width: "100%"}}/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Client Secret"
                                        name="client_secret"
                                        style={{ flex: '1 1 30%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Client Secret is required!",
                                            },
                                        ]}
                                    >
                                        <Input style={{ width: "100%"}}/>
                                    </Form.Item>
                                    <Form.Item
                                        label="User Name"
                                        name="user_name"
                                        style={{ flex: '1 1 30%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "User Name is required!",
                                            },
                                        ]}
                                    >
                                        <Input style={{ width: "100%"}}/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Password"
                                        name="password"
                                        style={{ flex: '1 1 30%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Password is required!",
                                            },
                                            {
                                                pattern: /[0-9a-zA-Z]{6,}/,
                                                message: "Password should be atleast 6 characters!",
                                            }
                                        ]}
                                        hasFeedback
                                    >
                                        <Input.Password style={{ width: "100%"}}/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Gst"
                                        name="gst"
                                        style={{ flex: '1 1 30%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Gst is required!",
                                            },
                                            {
                                                pattern: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z{1}$|^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z{1}[0-9A-Z]{1}$/,
                                                message: "Invalid GSTIN No. Enter a Valid GSTIN No.",
                                            }
                                        ]}
                                    >
                                        <Input style={{ width: "100%"}}/>
                                    </Form.Item>
                                    <Form.Item
                                            label="company code"
                                            name="company_code"
                                            style={{ flex: '1 1 30%' }}  
                                            initialValue={`${company?.company_code}`}   
                                            hidden                                      
                                    >
                                            <Input style={{ width: "100%"}} />
                                    </Form.Item>
                                </Form>
                            </div>
                            <Form form={form2} onFinish={onEinvoiceFinish} style={{marginTop: "20px"}}>
                                <Form.Item style={{marginTop: "20px"}}>
                                    <Button type="primary" htmlType="submit">
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </SystemSettingsWrapper>
                    </div>

                    <div className="bg_box" style={{marginTop: "20px"}}>
                        <SystemSettingsWrapper>
                            <div className="primary_section">
                                <h3 style={{fontWeight: "bold", marginTop: "0px"}}>UPI</h3>
                                <Form
                                    name="UPI"
                                    layout="inline"
                                    // onFinish={onFinish3}
                                    form={form3}
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        justifyContent: 'space-between',
                                        gap: '20px'  // Adjust the gap value as needed
                                    }}
                                >
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        style={{ flex: '1 1 30%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Name is required!",
                                            },
                                        ]}
                                    >
                                        <Input style={{ width: '100%'}}/>
                                    </Form.Item>
                                    <Form.Item
                                        label="UPI Id"
                                        name="upi_id"
                                        style={{ flex: '1 1 30%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "UPI Id is required!",
                                            },
                                        ]}
                                    >
                                        <Input style={{ width: '100%'}}/>
                                    </Form.Item>
                                    <Form.Item
                                            label="company code"
                                            name="company_code"
                                            style={{ flex: '1 1 30%' }}  
                                            initialValue={`${company?.company_code}`}   
                                            hidden                                      
                                    >
                                            <Input style={{ width: "100%"}} />
                                    </Form.Item>
                                </Form>
                            </div>
                            <Form form={form3} onFinish={onUPIFinish} style={{marginTop: "20px"}}>
                                <Form.Item style={{marginTop: "20px"}}>
                                    <Button type="primary" htmlType="submit">
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </SystemSettingsWrapper>
                    </div>

                    <div className="bg_box" style={{marginTop: "20px"}}>
                        <SystemSettingsWrapper>
                            <div className="primary_section">
                                <h3 style={{fontWeight: "bold", marginTop: "0px"}}>Payment Gateway</h3>
                                <Form
                                    name="paymentgateway"
                                    layout="inline"
                                    // onFinish={onFinish4}
                                    form={form4}
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        justifyContent: 'space-between',
                                        gap: '20px'  // Adjust the gap value as needed
                                    }}
                                >         
                                    
                                    <Form.Item
                                        label="Gateways"
                                        name="gateways"
                                        style={{ flex: '1 1 30%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Gateway is required!",
                                            },
                                        ]}
                                    >
                                         <Select
                                            // defaultValue=''
                                            placeholder="Select Gateway"
                                            style={{
                                                width: "100%",
                                            }}
                                            onChange={handleGatewayChange}
                                            options={[                                               
                                                {
                                                value: 'easebuzz',
                                                label: 'Easebuzz',
                                                },                                               
                                                {
                                                value: 'cash',
                                                label: 'Cash',
                                                },                                               
                                                {
                                                value: 'UPI',
                                                label: 'UPI',
                                                },                                               
                                            ]}
                                            />
                                    </Form.Item>
                                    <Form.Item
                                        label="Status"
                                        name="status"
                                        style={{ flex: '1 1 30%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Status is required!",
                                            },
                                        ]}
                                    >
                                         <Select
                                            // defaultValue=''
                                            placeholder="Select Payment Gateway Status"
                                            style={{
                                                width: "100%",
                                            }}
                                            onChange={handleChange}
                                            options={[
                                                // {
                                                // value: '',
                                                // label: 'Select',
                                                // },
                                                {
                                                value: 1,
                                                label: 'Enable',
                                                },
                                                {
                                                value: 0,
                                                label: 'Disable',
                                                },
                                            ]}
                                            />
                                    </Form.Item>
                                    <Form.Item
                                            label="company code"
                                            name="company_code"
                                            style={{ flex: '1 1 30%' }}  
                                            initialValue={`${company?.company_code}`}   
                                            hidden                                      
                                    >
                                            <Input style={{ width: "100%"}} />
                                    </Form.Item>
                                    {selectedGateway !== 'cash' && 
                                    <>
                                        <Form.Item
                                            label="Key"
                                            name="key"
                                            style={{ flex: '1 1 30%' }}
                                            rules={[
                                                {
                                                    required: selectedGateway === 'easebuzz'? true : false,
                                                    message: "Key is required!",
                                                },
                                            ]}
                                        >
                                            <Input style={{ width: "100%"}}/>
                                        </Form.Item>
                                        <Form.Item
                                            label="Salt"
                                            name="salt"
                                            style={{ flex: '1 1 30%' }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Salt is required!",
                                                },
                                            ]}
                                        >
                                            <Input style={{ width: "100%"}}/>
                                        </Form.Item>
                                    </>
                                    }
                                </Form>
                            </div>
                            <Form form={form4} onFinish={onPaymentGatewayFinish} style={{marginTop: "20px"}}>
                                <Form.Item style={{marginTop: "20px"}}>
                                    <Button type="primary" htmlType="submit">
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </SystemSettingsWrapper>
                    </div>

                    <div className="bg_box" style={{marginTop: "20px"}}>
                        <SystemSettingsWrapper>
                            <div className="primary_section">
                                <h3 style={{fontWeight: "bold", marginTop: "0px"}}>Shiprocket</h3>
                                <Form
                                    name="shiprocket"
                                    layout="inline"
                                    // onFinish={onFinish5}
                                    form={form5}
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        justifyContent: 'space-between',
                                        gap: '20px'  // Adjust the gap value as needed
                                    }}
                                >
                                    <Form.Item
                                        label="User Name"
                                        name="user_name"
                                        style={{ flex: '1 1 30%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "User Name is required!",
                                            },
                                        ]}
                                    >
                                        <Input style={{ width: "100%"}}/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Password"
                                        name="password"
                                        style={{ flex: '1 1 30%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Password is required!",
                                            },
                                            {
                                                pattern: /[0-9a-zA-Z]{6,}/,
                                                message: "Password should be atleast 6 characters!",
                                            }
                                        ]}
                                        hasFeedback
                                    >
                                        <Input.Password style={{ width: "100%"}}/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Sender Id"
                                        name="sender_id"
                                        style={{ flex: '1 1 30%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Sender Id is required!",
                                            },
                                        ]}
                                    >
                                        <Input style={{ width: "100%"}}/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Status"
                                        name="status"
                                        style={{ flex: '1 1 30%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Status is required!",
                                            },
                                        ]}
                                    >
                                         <Select
                                            defaultValue=''
                                            style={{
                                                width: "100%",
                                            }}
                                            onChange={handleChange}
                                            options={[
                                                // {
                                                // value: '',
                                                // label: 'Select',
                                                // },
                                                {
                                                value: 1,
                                                label: 'Enable',
                                                },
                                                {
                                                value: 0,
                                                label: 'Disable',
                                                },
                                            ]}
                                            />
                                    </Form.Item>
                                    <Form.Item
                                            label="company code"
                                            name="company_code"
                                            style={{ flex: '1 1 30%' }}  
                                            initialValue={`${company?.company_code}`}   
                                            hidden                                      
                                    >
                                            <Input style={{ width: "100%"}} />
                                    </Form.Item>
                                </Form>
                            </div>

                            <Form form={form5} onFinish={onShiprocketFinish} style={{marginTop: "20px"}}>
                                <Form.Item style={{marginTop: "20px"}}>
                                    <Button type="primary" htmlType="submit">
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </SystemSettingsWrapper>
                    </div>

                    <div className="bg_box" style={{marginTop: "20px"}}>
                        <SystemSettingsWrapper>
                            <div className="primary_section">
                                <h3 style={{fontWeight: "bold", marginTop: "0px"}}>Map</h3>
                                <Form
                                    name="Map"
                                    layout="inline"
                                    // onFinish={onFinish6}
                                    form={form6}
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        justifyContent: 'space-between',
                                        gap: '20px'  // Adjust the gap value as needed
                                    }}
                                >
                                    <Form.Item
                                        label="Key"
                                        name="key"
                                        style={{ flex: '1 1 30%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Name is required!",
                                            },
                                        ]}
                                    >
                                        <Input style={{ width: '100%'}}/>
                                    </Form.Item> 
                                    <Form.Item
                                            label="company code"
                                            name="company_code"
                                            style={{ flex: '1 1 30%' }}  
                                            initialValue={`${company?.company_code}`}   
                                            hidden                                      
                                    >
                                            <Input style={{ width: "100%"}} />
                                    </Form.Item>                                  
                                </Form>
                            </div>
                            <Form form={form6} onFinish={onMapFinish} style={{marginTop: "20px"}}>
                                <Form.Item style={{marginTop: "20px"}}>
                                    <Button type="primary" htmlType="submit">
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </SystemSettingsWrapper>
                    </div>

                    {/* <Form form={form} onFinish={onFinish} style={{marginTop: "20px"}}>
                        <Form.Item style={{marginTop: "20px"}}>
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>
                        </Form.Item>
                    </Form> */}
                </div>

            </div>   
        </SysytemSettings>              
    </React.Fragment>
  )
}

export default SystemSettings;

const SysytemSettings = styled.div`
    width: 100%;
    display: inline-block;
`;

const SystemSettingsWrapper = styled.div`
    width: 100%;
    display: inline-block;
`;